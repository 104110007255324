import React, { useEffect, useState, useCallback } from 'react';
import { IconButton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import secureApiFetch from '../../../services/secureFetchApi';
import toast from 'react-hot-toast';

const ScanFilter = ({ scanId, setDetails, scanType, setLoading }) => {
    const [filters, setFilters] = useState({ status: '', sort: '', descending: '', scan_type: '' });
    const fetchData = useCallback(async (url) => {
        setLoading(true);
        try {
            const config = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            };
            const res = await secureApiFetch(url, config);
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            const data = await res.json();
            setDetails(data);
        } catch (error) {
            console.error('Failed to retrieve data for this ID:', error);
            toast.error('Failed to retrieve data for this ID.');
        } finally {
            setLoading(false);
        }
    }, [setLoading, setDetails]);


    const fetchTasks = () => {
        const { status, sort, descending, scan_type } = filters;
        let url = `/api/v1/result/scan_code/${scanId}?`;
        if (status) url += `&scan_status=${status}`;
        if (sort) url += `&sort_by=${sort}`;
        if (descending) url += `&descending=${descending}`;
        if (scan_type) url += `&scan_type=${scan_type}`;
        fetchData(url);
    };

    useEffect(() => {
        if (scanId) {
            fetchTasks(1);
        }
    }, [filters.status, filters.sort, filters.descending, filters.scan_type, fetchData, scanId]);

    const handleFiltersChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const handleSortToggle = () => {
        const newDescending = filters.descending === 'true' ? 'false' : 'true';
        setFilters(prevFilters => ({ ...prevFilters, descending: newDescending }));
    };

    return (
        <div className="flex justify-end items-center">
            <div className='flex items-center'>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Status</InputLabel>
                    <Select name="status" label="Status" value={filters.status} onChange={handleFiltersChange}>
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="inprogress">In Progress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="canceled">Cancled</MenuItem>
                        <MenuItem value="failed">Failed</MenuItem>
                        <MenuItem value="yet_to_start">Yet To Start</MenuItem>
                    </Select>
                </FormControl>
                {scanType && (
                    <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                        <InputLabel>Scan Type</InputLabel>
                        <Select name="scan_type" label="Scan Type" value={filters.scan_type} onChange={handleFiltersChange}>
                            <MenuItem value="">All</MenuItem>
                            {scanType.map((scan) => (
                                <MenuItem value={scan}>{scan}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Sort By</InputLabel>
                    <Select
                        label="Sort By"
                        name="sort"
                        value={filters.sort}
                        onChange={handleFiltersChange}
                    >
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="insert_ts">Insert Ts</MenuItem>
                        <MenuItem value="update_ts">Update Ts</MenuItem>
                    </Select>
                </FormControl>

                <IconButton onClick={handleSortToggle}>
                    {filters.descending === 'true'
                        ? <ArrowDownwardIcon color="customColor" fontSize="small" />
                        : <ArrowUpwardIcon color="customColor" fontSize="small" />}
                </IconButton>
            </div>
        </div>
    );
};

export default ScanFilter