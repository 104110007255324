import React, { useEffect, useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';

const FormService = ({ isEdit = false, editService, onSubmit }) => {
    const [formData, setFormData] = useState({
        target_type: '',
        scan_type: '',
        status: 'active'
    });

    useEffect(() => {
        if (editService) {
            setFormData({
                target_type: editService.target_type || '',
                scan_type: editService.scan_type || '',
                status: editService.status || 'active'
            });
        }
    }, [editService]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newValue = name === 'target_type' ? value.toLowerCase() : value;
        setFormData({ ...formData, [name]: newValue });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <div className='flex justify-center'>
            <form onSubmit={handleSubmit} style={{ width: '50%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

                    <TextField label="Target Type" name="target_type" value={formData.target_type} onChange={handleChange} required />

                    <TextField label="Scan Type" name="scan_type" value={formData.scan_type} onChange={handleChange} required />

                    <FormControl>
                        <InputLabel>Status</InputLabel>
                        <Select name="status" label='Status' value={formData.status} onChange={handleChange} required>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                        </Select>
                    </FormControl>

                    <Button type="submit" variant="contained" color="customColor">
                        {isEdit === true ? 'Edit Service' : 'Create Service'}
                    </Button>

                </Box>
            </form>
        </div>
    );
};


export default FormService