import { IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import secureApiFetch from '../../../../services/secureFetchApi';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import './print.css'
import ReportPage from './ReportPage';
import ReportIntro from './ReportIntro';
import Overview from './Overview';
import Note from './Note';

import RenderSubfinder from '../scanners/RenderSubfinder';
import RenderWhoisData from '../scanners/RenderWhoisData';
import { RenderSslscan } from '../scanners/RenderSslscan';
import RenderOpenai from '../scanners/RenderOpenai';
import RenderShcheck from '../scanners/RenderShcheck';
import RenderWebvulnFinder from '../scanners/RenderWebvulnFinder';
import { formatString, pataString } from './SubName';
import KeyAssesment from './KeyAssesment';
import Renders3scanner from '../scanners/Renders3scanner';
import CompanyInfo from '../form';

const ResultData = () => {
    const [data, getData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
    const jobIds = localStorage.getItem('jobIds');
    let jobs = '';
    if (jobIds) {
        jobs = jobIds.split(',');
    }
    const [open, setOpen] = useState(false);
    const printRef = useRef(null);

    const handlePrint = () => {
        if (printRef.current) {
            printRef.current.handlePrint();
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFetchSelectedData = () => {
        if (jobs) {
            const promises = jobs.map((jobId) => {
                return secureApiFetch(`/api/v1/result/job_id/${jobId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access': 'application/json'
                    }
                })
                    .then(res => {
                        if (res.status === 204) {
                            return null;
                        }
                        return res.json();
                    })
                    .catch(error => {
                        console.error(error);
                        return { error: true, message: 'Failed to fetch data for this job.' };
                    });
            });

            Promise.all(promises)
                .then(dataArray => {
                    const validData = dataArray.filter(item => item && !item.error);
                    if (validData.length > 0) {
                        getData(validData);
                    } else {
                        setError("No valid job data found. Please check your entries and try again.");
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setError('An error occurred while fetching the data.');
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (jobs && jobs.length > 0) {
            handleFetchSelectedData();
        } else {
            setError('It appears that no job data was found. Please check your entries and try again.');
            setLoading(false);
        }
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 p-6">
            <ReactToPrint
                ref={printRef}
                content={() => document.querySelector('#table-to-print')}
            />
            <div className="bg-gray-800 p-4 rounded-md shadow-md">
                <div className='flex items-center justify-between'>
                    <h1 className="text-2xl font-bold text-white mb-4">Result</h1>
                    <IconButton onClick={handleClickOpen} >
                        <Tooltip title='Print this out!'>
                            <PrintIcon className='text-[#ffff]' />
                        </Tooltip>
                    </IconButton>
                </div>
                {loading && (
                    <div className="bg-white p-5 rounded-md shadow-sm h-[80vh] text-lg flex justify-center items-center">
                        Just a moment, we're fetching the details...
                    </div>
                )}
                {!loading && error && (
                    <div className="bg-white p-5 rounded-md shadow-sm h-[80vh] text-center">
                        <h2 className="text-xl font-bold text-red-500">{error}</h2>
                    </div>
                )}
                {!loading && !error && (
                    <div id='table-to-print' className="bg-white p-5 rounded-md shadow-sm">
                        <div className="cover-page">
                            <ReportPage nameTarget={companyInfo} />
                            <ReportIntro companyInfo={companyInfo} />
                            <Overview />
                            <KeyAssesment />
                        </div>

                        {Array.isArray(data) && data.map((job, jobIndex) => (
                            <div key={jobIndex} className="mb-8 pb-4 page-break paddingTop">
                                {Object.entries(job).map(([domain, tasks]) => (
                                    <div key={domain}>
                                        {Object.entries(tasks).map(([taskName, taskDetails], taskIndex) => (
                                            <div key={taskIndex} className="mb-6">
                                                <div className="flex flex-col mb-4">
                                                    <h3 className="text-xl font-semibold text-white bg-[#3A3D89] py-1 px-1">
                                                        {jobIndex + 1}. {formatString(taskName)}
                                                    </h3>
                                                    <div className="text-lg text-gray-600">
                                                        {pataString(taskName)}
                                                    </div>
                                                </div>
                                                <div className="mt-4 pl-4 bg-[#F9F9F9] p-4 rounded-md shadow-inner">
                                                    <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Target: {domain}</h4>
                                                    <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Results:</h4>
                                                    <div className="pl-4">
                                                        {taskDetails.length === 0 ? (
                                                            <div className="text-gray-600 italic">
                                                                No results available.
                                                            </div>
                                                        ) : (
                                                            taskDetails.map((detail, detailIndex) => (
                                                                <div key={detailIndex} className="mb-4 mt-2">
                                                                    {detail.result &&
                                                                        <> {
                                                                            Array.isArray(detail.result) && detail.result.map((result, resultIndex) => (
                                                                                <div key={resultIndex} className="mt-2">
                                                                                    {taskName === 'subfinder' && <RenderSubfinder result={result} />}
                                                                                    {taskName === 'whois' && <RenderWhoisData result={result} />}
                                                                                    {taskName === 'sslscan' && <RenderSslscan data={result} />}
                                                                                    {taskName === 'openai' && <RenderOpenai result={result} />}
                                                                                    {taskName === 'shcheck' && <RenderShcheck result={result} />}
                                                                                    {taskName === 'webvuln_finder' && <RenderWebvulnFinder result={result} />}
                                                                                    {taskName === 's3scanner' && <Renders3scanner result={result} />}
                                                                                    {taskName !== 's3scanner' && taskName !== 'webvuln_finder' && taskName !== 'shcheck' && taskName !== 'subfinder' && taskName !== 'openai' && taskName !== 'sslscan' && taskName !== 'whois' && (
                                                                                        <ul className="text-md list-inside list-disc text-gray-800">
                                                                                            {Object.entries(result).map(([key, value]) => (
                                                                                                <li key={key}>
                                                                                                    <span className="font-semibold text-[#3A3D89]">{key}: </span>
                                                                                                    {value}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    )}
                                                                                </div>
                                                                            ))
                                                                        } </>
                                                                    }
                                                                    {(!detail.result || !Array.isArray(detail.result) || detail.result.length === 0|| detail.result.every(result => Object.keys(result).length === 0)) && (
                                                                        <div className="text-gray-600 italic">
                                                                            No results available.
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}

                        <div className='note-page'>
                            <Note />
                        </div>
                    </div>
                )}
            </div>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Report Details
                </DialogTitle>
                <DialogContent>

                    <CompanyInfo handleClose={handleClose} onPrint={handlePrint} />
                </DialogContent>
            </Dialog>
        </div >
    );
};

export default ResultData;
