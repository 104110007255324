import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Cron } from 'react-js-cron'
import { Link, useNavigate } from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import secureApiFetch from '../../services/secureFetchApi';
import 'react-js-cron/dist/styles.css';
import Folder from '../../ui/Folder';

const CreateCron = () => {
  const [formData, setFormData] = useState({
    client_id: 0,
    target: '',
    target_type: '',
    user_id: 0,
    cron: '',
    level: 3,
    schedule_count: 1
  });
  const [valueCron, setValueCron] = useState(formData.cron)
  const [targetType, setTargetType] = useState([])
  const navigate = useNavigate()

  const getSerivceTarget = () => {
    secureApiFetch(`/api/v1/service`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const targetTypes = [...new Set(data.map((item) => item.target_type))];
        setTargetType(targetTypes)
      })
      .catch((error) => {
        toast.error('Failed to submit form');
        console.error('Error submitting form:', error.message);
      });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'schedule_count') {
      const numericValue = parseInt(value, 10);
      if (numericValue <= 0 && value !== '') {
        return;
      }
    }
    setFormData({ ...formData, [name]: value, cron: valueCron });
  };

  useEffect(() => {
    setFormData({ ...formData, cron: valueCron });
  }, [formData])

  useEffect(() => {
    getSerivceTarget()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    secureApiFetch(`/api/v1/schedule_scan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then((res) => {
        if (res.status === 500) {
          throw new Error('500');
        }
        if (!res.ok) {
          throw new Error('Failed to submit form');
        }
        toast.success('Form submitted successfully');
        navigate('/schedulescan')
      })
      .catch((error) => {
        if (error.message === '500') {
          toast.error('Same scan already present in the queue');
        } else {
          toast.error('Failed to submit form');
        }
        console.error('Error submitting form:', error.message);
      });
  };


  return (
    <div className="bg-white shadow rounded min-h-screen py-1 px-3">

      <div>
        <NavigateBreadCrumbs>
          <Link to='/' >Home</Link>
          <Link to="/schedulescan">Schedule Scan</Link>
          <span>Create Schedule Scan</span>
        </NavigateBreadCrumbs>
        <Folder label='Create Schedule Scan' icon={<EditCalendarIcon color='3A3D89' />} />
      </div>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          <div className="space-y-4">

            <TextField name="client_id" label="Client ID" value={formData.client_id} onChange={handleChange} fullWidth />

            <TextField name="target" label="Target" value={formData.target} onChange={handleChange} fullWidth required />

            <TextField name="cron" label="Cron" value={valueCron} InputProps={{ readOnly: true }} fullWidth required />

            <Cron value={valueCron} setValue={setValueCron} />

          </div>
          <div className="space-y-4">

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Level</InputLabel>
              <Select name="level" labelId="demo-simple-select-label" id="demo-simple-select" value={formData.level} label="Level" onChange={handleChange}>
                <MenuItem value={1}>Level 1: Fast Scan</MenuItem>
                <MenuItem value={2}>Level 2: Balanced Scan</MenuItem>
                <MenuItem value={3}>Level 3: Moderate Scan</MenuItem>
                <MenuItem value={4}>Level 4: Deep Scan</MenuItem>
              </Select>
            </FormControl>

            <TextField name="schedule_count" label="Schedule Count" value={formData.schedule_count} onChange={handleChange} fullWidth />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Target Type</InputLabel>
              <Select name="target_type" labelId="demo-simple-select-label" id="demo-simple-select" value={formData.target_type} label="Target Type" onChange={handleChange} required>
                {targetType.map((target) => (
                  <MenuItem value={target}>{target}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField name="user_id" label="User ID" value={formData.user_id} onChange={handleChange} fullWidth />
          </div>

          <div className="fixed bottom-6 right-10">
            <Button type="submit" variant="contained" color="customColor">
              Create Schedule
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateCron