import React from 'react'

const RenderShcheck = ({result}) => {
  return (
    <div className="p-4 bg-gray-50">
                <ul className="list-disc list-inside space-y-3 text-gray-800">
                    <li>
                        <strong className="text-[#3A3D89]">Present Headers:</strong>
                        <ul className="list-disc list-inside ml-4">
                            {Object.entries(result.present).map(([key, value]) => (
                                <li key={key}>
                                    <strong className="text-[#3A3D89]">{key}:</strong> {value}
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li>
                        <strong className="text-[#3A3D89]">Missing Headers:</strong>
                        <ul className="list-disc list-inside ml-4">
                            {result.missing.map((header, index) => (
                                <li key={index} className="text-gray-800">
                                    {header}
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
            </div>
  )
}

export default RenderShcheck