import React from 'react';

const HoleheJobIdDetails = ({ result }) => {
  const renderDetails = (item) => {
    return (
      <ul className="list-disc list-inside space-y-1 text-gray-800">
        {Object.keys(item).map((key, index) => (
          <li key={index}>
            <strong className="text-[#3A3D89] capitalize">{key.replace(/_/g, ' ')}:</strong> {item[key] !== null && item[key] !== undefined ? item[key].toString() : "N/A"}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="p-4">
      {result.map((item, index) => (
        <div key={index} className="mb-6">
          {renderDetails(item)}
        </div>
      ))}
    </div>
  );
};

export default HoleheJobIdDetails;
