import React from 'react';

const ShodanResult = ({ result }) => {
    const renderValue = (value) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
            return (
                <div>
                    {Object.entries(value).map(([subKey, subValue]) => (
                        <div key={subKey} className="flex">
                            <strong>{subKey}</strong>: <p>{renderValue(subValue)}</p>
                        </div>
                    ))}
                </div>
            );
        }
        return value;
    };

    if (!result) {
        return <div className="text-red-500">"error": "Invalid IP"</div>; // Error message for undefined result
    }

    return (
        <div>
            {Array.isArray(result) ? (
                result.map((resultItem, idx) => (
                    <div key={idx}>
                        {Object.entries(resultItem).map(([key, value]) => (
                            <div key={key} className="flex">
                                <strong>{key}</strong>: <p>{renderValue(value)}</p>
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <div>{result}</div>
            )}
        </div>
    );
};

export default ShodanResult;
