import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';

const CompanyInfo = ({ handleClose,onPrint  }) => {
    const [formData, setFormData] = useState({
        companyName: '',
        startDate: '',
        endDate: '', 
        goals: `The primary goal of an Attack Surface Scan is to reduce the organization's exposure to cyber threats by identifying and securing every access point that could be exploited by an attacker. It provides visibility into potential weaknesses, allowing organizations to fix vulnerabilities before they are leveraged in attacks. This scan ensures that every aspect of the attack surface—from public-facing websites and network ports to internal services—is adequately secured.
        Identify Attack Vectors: Map out all external and internal access points that could be exploited by malicious actors.
Assess Risk Exposure: Quantify the potential risk associated with each attack vector based on severity and likelihood of exploitation.
Evaluate Security Posture: Provide an understanding of how well current security measures protect against attacks and identify gaps in protection.
Prioritize Critical Assets: Focus on securing the most sensitive assets, such as critical infrastructure and high-value data`,
        otherConsiderations: `Both vulnerability assessments and penetration tests provide only a snapshot of the security posture. Given that security exposure is ever-changing, information security management must be regularly monitored, reviewed, and audited. An ongoing process for improvement and corrective action should be implemented. Even after addressing the vulnerabilities mentioned below, we recommend conducting periodic Attack Surface Scan, External and Internal Vulnerability Assessments and Penetration Testing, as well as Security Audit Reviews, especially after significant changes in systems and infrastructure.`,
        keyAssesment:`Associated Domains: Identify and monitor all domains connected to your organization.
Code & Repositories: Detect exposed code and sensitive data in public repositories to prevent unauthorized access.
Exposed Services: Uncover publicly accessible services and applications that may be vulnerable.
Compromised Emails & Users: Find and secure exposed or compromised email accounts and user credentials.
Leaked Credentials: Identify any exposed passwords or keys to prevent breaches.
IP Addresses: Map and assess public IP addresses for potential vulnerabilities.
SSL Monitoring: Ensure SSL certificates are valid and correctly configured to secure online transactions.
Darknet Monitoring: Scan the dark web for threats and mentions of your assets to address risks proactively.
Subdomain Takeover Risks: Prevent subdomain hijacking by identifying and securing vulnerable entries.
S3 & Object Storage Security: Detect misconfigured or exposed cloud storage resources to prevent data leaks.`,
        reportSelection: ''
    });

    const [formErrors, setFormErrors] = useState({
        companyName: false,
        startDate: false,
        endDate: false,
        goals: false,
        otherConsiderations: false,
        keyAssesment: false,
        reportSelection: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleViewClick = () => {
        const errors = {
            companyName: !formData.companyName,
            startDate: !formData.startDate,
            endDate: !formData.endDate,
            goals: !formData.goals,
            otherConsiderations: !formData.otherConsiderations,
            keyAssesment: !formData.keyAssesment,
            reportSelection: !formData.reportSelection
        };

        if (Object.values(errors).includes(true)) {
            setFormErrors(errors);
            return;
        }

        localStorage.setItem('companyInfo', JSON.stringify(formData));
        handleClose();
        setTimeout(() => {
            onPrint();
        }, 2000);
    };

    return (
        <div>
            <form className="space-y-6 mt-4">
                <TextField
                    id="company-name"
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                    error={formErrors.companyName}
                    helperText={formErrors.companyName ? 'Company name is required' : ''}
                />

                <TextField
                    id="start-date"
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: formData.endDate || undefined,
                    }}
                    required
                    error={formErrors.startDate}
                    helperText={formErrors.startDate ? 'Start date is required' : ''}
                />

                <TextField
                    id="end-date"
                    label="End Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        min: formData.startDate || undefined,
                    }}
                    required
                    error={formErrors.endDate}
                    helperText={formErrors.endDate ? 'End date is required' : ''}
                />

                <div className="space-y-4 mt-4">
                    <h3 className="text-lg font-medium">Overview</h3>

                    <TextField
                        id="goals"
                        label="Goals and Objective"
                        variant="outlined"
                        fullWidth
                        name="goals"
                        value={formData.goals}
                        onChange={handleChange}
                        multiline
                        rows={7}
                        required
                        error={formErrors.goals}
                        helperText={formErrors.goals ? 'Goals and Objective is required' : ''}
                    />

                    <TextField
                        id="other-considerations"
                        label="Other Considerations"
                        variant="outlined"
                        fullWidth
                        name="otherConsiderations"
                        value={formData.otherConsiderations}
                        onChange={handleChange}
                        multiline
                        rows={6}
                        required
                        error={formErrors.otherConsiderations}
                        helperText={formErrors.otherConsiderations ? 'Other Considerations are required' : ''}
                    />
                    <TextField
                        id="other-considerations"
                        label="Key Assesment Areas"
                        variant="outlined"
                        fullWidth
                        name="keyAssesment"
                        value={formData.keyAssesment}
                        onChange={handleChange}
                        multiline
                        rows={6}
                        required
                        error={formErrors.keyAssesment}
                        helperText={formErrors.keyAssesment ? 'Key Assesment Areas are required' : ''}
                    />
                </div>

                <div className="mt-4">
                    <FormControl fullWidth>
                        <InputLabel id="report-select-label">Select Report</InputLabel>
                        <Select
                            labelId="report-select-label"
                            id="report-select"
                            name="reportSelection"
                            label="Select Report"
                            value={formData.reportSelection}
                            onChange={handleChange}
                            error={formErrors.reportSelection}
                            helperText={formErrors.reportSelection ? 'Report is required' : ''}
                        >
                            <MenuItem value="Securityium">Securityium</MenuItem>
                            <MenuItem value="SecureIt">SecureIt</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="flex justify-end space-x-4 px-4 py-2">
                    <Button onClick={handleClose} color='customColor' className="text-red-500">
                        Cancel
                    </Button>
                    <Button onClick={handleViewClick} color='customColor' variant="contained">
                        Print
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default CompanyInfo;
