import React, { useState } from 'react'
import { TextField, Button } from '@mui/material';
import logo from '../../assets/images/logo.png';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    designation: '',
    user_role: 'user',
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    console.log('Form submitted:', formData);
    setLoading(false);
  };

  return (
    <section>
      {loading && <div className="flex justify-center"><p>Loading...</p></div>}
      <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-20">
        <div className="mx-auto w-full max-w-sm md:max-w-md lg:max-w-md xl:max-w-md 2xl:max-w-md p-10 bg-gray-50 shadow-blue-200 shadow-xl">
          <div className="mb-5 p-5 flex justify-center">
            <img src={logo} alt="Logo" style={{ width: "50%" }} />
          </div>
          <div>
            <h2 className="text-center text-2xl font-bold leading-tight text-black">
              User Registration
            </h2>

            <form onSubmit={handleSubmit} className="mt-8 space-y-5">
              {/* Username */}
              <div>
                <TextField
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  InputProps={{
                    className:
                      "rounded-md bg-transparent text-sm focus:ring-1 focus:ring-gray-400",
                  }}
                />
              </div>

              {/* Email */}
              <div>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  InputProps={{
                    className:
                      "rounded-md bg-transparent text-sm focus:ring-1 focus:ring-gray-400",
                  }}
                />
              </div>

              {/* Password */}
              <div>
                <TextField
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  InputProps={{
                    className:
                      "rounded-md bg-transparent text-sm focus:ring-1 focus:ring-gray-400",
                  }}
                />
              </div>

              {/* Designation */}
              <div>
                <TextField
                  fullWidth
                  id="designation"
                  label="Designation"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    className:
                      "rounded-md bg-transparent text-sm focus:ring-1 focus:ring-gray-400",
                  }}
                />
              </div>

              {/* User Role (Hidden field for simplicity) */}
              <input
                type="hidden"
                name="user_role"
                value={formData.user_role}
              />

              {/* Error Handling */}
              {error && (
                <div>
                  <p className="text-red-500">Error: {error}</p>
                </div>
              )}

              {/* Submit Button */}
              <div>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#282560", color: "#fff" }}
                  className="inline-flex justify-center rounded-md px-3.5 py-2.5 font-semibold leading-7 hover:bg-black/80"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register