import React from 'react'

const ColorBadge = ({ badge, textSize='sm' }) => {
    let badgeColorClass = '';
    switch (badge) {
        case 'canceled':
            badgeColorClass = 'bg-[#FF0000] text-white';
            break;
        case 'dependent_hold':
            badgeColorClass = 'bg-red-200 text-red-800';
            break;
        case 'todo':
            badgeColorClass = 'bg-[#FFC000] text-white';
            break;
        case 'scheduled':
            badgeColorClass = 'bg-[#00B050] text-white';
            break;
        case 'completed':
            badgeColorClass = 'bg-[#0070C0] text-white';
            break;
        case 'inprogress':
            badgeColorClass = 'bg-[#00B050] text-white';
            break;
        case 'do_first':
            badgeColorClass = 'bg-[#C00000] text-white';
            break;
        case 'schedule':
            badgeColorClass = 'bg-[#00B050] text-white';
            break;
        case 'delegate':
            badgeColorClass = 'bg-indigo-200 text-indigo-800';
            break;
        case 'eliminate':
            badgeColorClass = 'bg-[#FF0000] text-white';
            break;
        case 'True':
            badgeColorClass = 'bg-[#00B050] text-white';
            break;
        case 'active':
            badgeColorClass = 'bg-[#00B050] text-white';
            break;
        case 'False':
            badgeColorClass = 'bg-[#A9A9A9] text-white';
            break;
        default:
            badgeColorClass = 'bg-gray-100 text-gray-800';
    }
    const badgeContent = badge ? badge : "-";
    return (
        <div>
            <span className={`text-${textSize} me-2 px-2 py-1 rounded ${badgeColorClass}`}>
                {badgeContent}
            </span>
        </div>
    );
};

export default ColorBadge