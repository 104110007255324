import React from 'react';

const ReportIntro = ({ companyInfo }) => {
    if (!companyInfo) {
        return null;
    }
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${month} ${day}${getOrdinalSuffix(day)} ${year}`;
    };



    return (
        <div className="px-4 pt-1 bg-white h-screen">
            <div className="flex flex-col items-center mb-8">
                <label className="text-2xl font-semibold text-[#3A3D89] mr-4">
                    Attack Surface Scan Report
                </label>
                {/* <div className='flex space-x-2'>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                </div> */}
            </div>
            <div className=''>
                <h1 className="text-2xl font-bold text-white bg-[#3A3D89] mb-4 py-1 px-2">1. Introduction</h1>
                <p className="text-lg text-gray-700 leading-relaxed pr-6">
                    {companyInfo.reportSelection} performed an Attack Surface Scan Report assessment for {companyInfo.companyName}. from {formatDate(companyInfo.startDate)} to {formatDate(companyInfo.endDate)}.The main objective of the Attack Surface Scan assessment was to thoroughly identify and assess all potential entry points (attack vectors) where an unauthorized user might attempt to access or extract data from a system. This proactive security approach helps organizations gain a clear understanding of their exposure to cyber threats by mapping vulnerabilities, misconfigurations, and weaknesses in digital assets such as networks, applications, and infrastructure.

                    By routinely performing Attack Surface Scans, organizations can reduce risks, enhance incident response, and strengthen their overall cybersecurity posture by addressing potential vulnerabilities before they can be exploited.
                </p>
            </div>
        </div>
    );
}

export default ReportIntro;
