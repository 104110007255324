import React from 'react';
import './LoadingSpinner.css'

const LoadingSpinner = () => {
    return (
        // <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-300 opacity-75 z-50">
        //     <div className="w-16 h-16 border-t-4 border-b-4 border-blue-800 rounded-full animate-spin"></div>
        // </div>
        <figure className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white z-50">
            <span className="loader"></span>
        </figure>
    );
};
const LoadingSpinnerOpacity = () => {
    return (
        // <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-300 opacity-75 z-50">
        //     <div className="w-16 h-16 border-t-4 border-b-4 border-blue-800 rounded-full animate-spin"></div>
        // </div>
        <figure className="fixed top-0 left-0 w-full h-full flex justify-center items-center opacity-75 bg-white z-50">
            <span className="loader"></span>
        </figure>
    );
};

export default LoadingSpinner;
export { LoadingSpinnerOpacity };
