import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({ Component }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            setIsAuthenticated(true); 
        }
    }, [navigate]); 

    if (isAuthenticated === null) {
        return null; 
    }

    return (
        <div>
            <Component />
        </div>
    );
};
