import React from 'react';
import DateFormat from '../../ui/DateFormat';

const ScanData = ({scheduleDetals}) => {
    return (
        <div>
            <div className="p-4 bg-blue-50 rounded-md shadow flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 pr-4 font-medium">
                    <div className="mb-4 font-bold text-lg"></div>
                    <div className="mb-2 text-sm">Status: {scheduleDetals.status}</div>
                    <div className="mb-2 text-sm">Target: {scheduleDetals.target}</div>
                    <div className="mb-2 text-sm">Cron: {scheduleDetals.cron}</div>
                    <div className="mb-2 text-sm">Target Type: {scheduleDetals.target_type}</div>
                    <div className="mb-2 text-sm">Level: {scheduleDetals.level}</div>
                    <div className="mb-2 text-sm">Client ID: {scheduleDetals.client_id}</div>
                </div>
                <div className="w-full md:w-1/2 pl-4 font-medium">
                    <div className="mb-2 text-sm">User ID: {scheduleDetals.user_id}</div>
                    <div className="mb-2 text-sm">ID: {scheduleDetals.id}</div>
                    <div className="mb-2 text-sm">Schedule Count: {scheduleDetals.schedule_count}</div>
                    <div className="mb-2 text-sm flex">Inserted Timestamp: &nbsp;<DateFormat date={scheduleDetals.insert_ts} /></div>
                    <div className="mb-2 text-sm flex">Updated Timestamp: &nbsp;<DateFormat date={scheduleDetals.update_ts} /> </div>
                    <div className="mb-2 text-sm flex">Last Scheduled For: &nbsp;<DateFormat date={scheduleDetals.last_scheduled_for} /> </div>
                </div>
            </div>
        </div>


    );
}

export default ScanData