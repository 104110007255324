import React from 'react'
import { Link } from 'react-router-dom';

import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import Folder from '../../ui/Folder';
import QuickDetails from './details';

const RunQuickScan = () => {

    return (
        <div className='bg-white  shadow rounded'>
            <div className='py-1 px-3'>
                <NavigateBreadCrumbs>
                    <Link to='/'>Home</Link>
                    <span>Run Quick Scan</span>
                </NavigateBreadCrumbs>
                <Folder label='Run Quick Scan' icon={<CropFreeOutlinedIcon color='#3D3A89' />} />
            </div>
            <hr />
            <div className='min-h-[75vh] mt-2'>
                <QuickDetails />
            </div>
        </div>
    )
}

export default RunQuickScan