import React, { useEffect, useState } from 'react'
import secureApiFetch from '../../../services/secureFetchApi'
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DotLoader from '../../../styles/DotLoader';
import DateFormat from '../../../ui/DateFormat';
import ColorBadge from '../../../ui/ColorBadge';

const QueueResult = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getQueueData = () => {
        setLoading(true);
        secureApiFetch(`/api/v1/queue-status`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((res) => {
                if (res.status === 204) {
                    setLoading(false);
                    setData([]);
                    return;
                }
                return res.json();
            })
            .then((data) => {
                setLoading(false);
                if (data) {
                    setData(data);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error('Client ID Not Found:', error);
            });
    }
    useEffect(() => {
        getQueueData()
    }, [])
    const extractJobData = (item) => {
        const key = Object.keys(item)[0];
        const jobInfo = item[key]['upcoming Job'];
        const { job_uid, scan_type, data, ts } = jobInfo;
        return { job_uid, scan_type, request_body: data.request_body, ts, totalJobs: item[key]['total jobs in queue'] };
    };
    return (
        <div >
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table className="table-container">
                        <TableHead className="table-heading text-left">
                            <TableRow>
                                <TableCell align="center">Scan Type</TableCell>
                                <TableCell align="center">Job ID</TableCell>
                                <TableCell align="center">Timestamp</TableCell>
                                <TableCell align="center">Total Jobs in Queue</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableCell colSpan={5} sx={{ padding: '1.5rem 0rem' }}>
                                        <DotLoader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && data.length === 0 && (
                                <TableRow>
                                    <TableCell sx={{ paddingY: 4 }} colSpan={5} align="center" className='py-3 font-bold text-xl text-red-600'>
                                        Nothing to see here... yet.
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && data.length > 0 && data.map((item, index) => {
                                const { job_uid, scan_type, request_body, ts, totalJobs } = extractJobData(item);
                                return (
                                    <TableRow key={index} className="data-row text-left">
                                        <TableCell align="center" sx={{ paddingY: 2 }} >{scan_type}</TableCell>
                                        <TableCell align="center">{job_uid}</TableCell>
                                        <TableCell align="center">
                                            <DateFormat date={ts} /> </TableCell>
                                        <TableCell align="center">{totalJobs}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default QueueResult