import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import BackupTableIcon from '@mui/icons-material/BackupTable';
import { FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import PaginationTable from '../../feature/PaginationTable';
import secureApiFetch from '../../services/secureFetchApi';
import Folder from '../../ui/Folder';
import ServicesTable from './table';

const ServicesList = () => {
    const [service, setService] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked((prevChecked) => !prevChecked);
    };

    const getData = (page = 1) => {
        secureApiFetch(`/api/v1/service?fetchall=${!checked}&page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((res) => {
                const totalPagesCount = parseInt(res.headers.get('X-Page-Count'));
                setTotalPages(totalPagesCount);
                return res.json();
            })
            .then((data) => {
                setService(data);
            })
            .catch((error) => {
                console.error('Failed to get data', error);
            });
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        getData(newPage);
    };

    useEffect(() => {
        getData(currentPage);
    }, [checked, currentPage]);

    return (
        <div className='bg-white shadow rounded'>
            <div className='flex justify-between items-center py-1 px-3'>
                <div className=''>
                    <NavigateBreadCrumbs>
                        <Link to='/'>Home</Link>
                        <span>Services</span>
                    </NavigateBreadCrumbs>
                    <Folder label='Services Table' icon={<BackupTableIcon color='#3D3A89' />} />
                </div>
                <PaginationTable totalPages={totalPages} page={currentPage} changePage={handleChangePage} />
                <Link to='/services/create'>
                    <Button variant="contained" color='customColor' endIcon={<AddIcon />}>
                        Add Service
                    </Button>
                </Link>
            </div>
            <hr />
            <div className='min-h-[75vh] px-2 py-2'>
                <span className='bg-gray-100 shadow px-2 py-1 rounded'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                color='customColor'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Is Active"
                    />
                </span>

                <ServicesTable service={service} getData={getData} />
            </div>
        </div>
    );
};

export default ServicesList;
