import { Route } from "react-router-dom";
import ScheduleScan from "./list";
import CreateCron from "./create";
import ScanDetails from "./details";
import ScheduleEdit from "./edit";

const ScheduleScanRoute = [
    <Route path='schedulescan' element={<ScheduleScan />} />,
    <Route path='schedulescan/:id' element={<ScanDetails />} />,
    <Route path='schedulescan/create' element={<CreateCron />} />,
    <Route path='schedulescan/edit/:scan_id' element={<ScheduleEdit />} />,
]
export default ScheduleScanRoute 