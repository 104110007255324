import React from 'react';

const DateFormat = ({ date }) => {
  if (!date) {
    return <div><p>-</p></div>;
  }

  const formattedDate = new Date(date);

  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const formattedDateString = formattedDate.toLocaleDateString('en-US', options);

  const addSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1: return `${day}st`;
      case 2: return `${day}nd`;
      case 3: return `${day}rd`;
      default: return `${day}th`;
    }
  };

  const day = formattedDate.getDate();
  const month = formattedDate.toLocaleString('en-US', { month: 'long' });

  const formattedDay = addSuffix(day);

  return (
    <div>
      <p>{formattedDay} {month}, {formattedDate.getFullYear()}</p>
    </div>
  );
};

export default DateFormat;
