import QueueResult from "./dashboardlist/QueueResult";

const DashLink = {
    'StatusCount': {
        title: 'Queue Status',
        visible: true,
        space: 2,
        component: <QueueResult />
    },
    

};

export default DashLink;
