import React from 'react'

const RenderSubfinder = ({ result }) => {
    return (
        <ul className="text-md list-inside list-disc text-[#3A3D89] pl-4 space-y-2">
            {Object.entries(result).map(([key, value]) => (
                <li key={key} className="leading-relaxed">
                    {value}
                </li>
            ))}
        </ul>
    )
}

export default RenderSubfinder