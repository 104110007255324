import React from 'react'
import { Link } from 'react-router-dom';
import { Grid, Paper, Box } from '@mui/material';

import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import Folder from '../../ui/Folder'
import DashLink from './DashLink';

const Dashboard = () => {
  const calculateGridItemWidth = (space) => {
    switch (space) {
      case 1:
        return 4;
      case 2:
        return 6;
      case 3:
        return 8;
      case 4:
        return 12;
      default:
        return 4;
    }
  };
  return (
    <div>
      <div className='bg-white p-3 shadow rounded'>
        <NavigateBreadCrumbs>
          <Link to='/' >Home</Link>
        </NavigateBreadCrumbs>
        <div className='flex justify-between items-center '>
          <Folder label='Dashboard' icon={<BackupTableIcon color='#3D3A89' />} />
        </div>
        <div className='min-h-[80vh]'>
          <div className='my-4'>
            <div className='grow px-2'>
              <Grid container spacing={1}>
                {Object.entries(DashLink).map(([key, value]) => (
                  <Grid item xs={20} sm={calculateGridItemWidth(value.space)} key={key}>
                    {value.visible && (
                      <Paper sx={{ padding: 1, textAlign: 'left' }}>
                        <Box sx={{ marginBottom: 1 }}>
                          <h3 className='font-medium text-sm'>{value.title}</h3>
                        </Box>
                        {value.component}
                      </Paper>
                    )}
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard