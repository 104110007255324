import { Route } from "react-router-dom";
import ResultList from "./list";
import ScanDetails from "./ScanDetails";
import JobDetails from "./JobDetails";

const ResultRoute = [
    <Route path={`/result`} element={<ResultList />} />,
    <Route path={`/result/scan/:id`} element={<ScanDetails />} />,
    <Route path={`/result/job/:job_id`} element={<JobDetails />} />,
]
export default ResultRoute;