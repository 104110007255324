import React from 'react'

const RenderOpenai = ({ result }) => {

    return (
        <div className="p-4 bg-gray-50">
            <ul className="list-disc list-inside space-y-3 text-gray-800">
                {result.name && (
                    <li><strong className="text-[#3A3D89]">Name:</strong> {result.name}</li>
                )}
                {result.website && (
                    <li><strong className="text-[#3A3D89]">Website:</strong> <a href={`https://${result.website}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{result.website}</a></li>
                )}
                {result.industry && (
                    <li><strong className="text-[#3A3D89]">Industry:</strong> {result.industry}</li>
                )}
                {result.description && (
                    <li><strong className="text-[#3A3D89]">Description:</strong> {result.description}</li>
                )}
                {result.company_founded_year && (
                    <li><strong className="text-[#3A3D89]">Founded Year:</strong> {result.company_founded_year}</li>
                )}
                {result.contact_email && (
                    <li><strong className="text-[#3A3D89]">Contact Email:</strong> {result.contact_email}</li>
                )}
                {result.address && (
                    <li><strong className="text-[#3A3D89]">Address:</strong> {result.address}</li>
                )}
                {result.city && (
                    <li><strong className="text-[#3A3D89]">City:</strong> {result.city}</li>
                )}
                {result.state && (
                    <li><strong className="text-[#3A3D89]">State:</strong> {result.state}</li>
                )}
                {result.country && (
                    <li><strong className="text-[#3A3D89]">Country:</strong> {result.country}</li>
                )}
                {result.postal_code && (
                    <li><strong className="text-[#3A3D89]">Postal Code:</strong> {result.postal_code}</li>
                )}
                {result.linkedin_url && (
                    <li><strong className="text-[#3A3D89]">LinkedIn:</strong> <a href={result.linkedin_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{result.linkedin_url}</a></li>
                )}
                {result.twitter_url && (
                    <li><strong className="text-[#3A3D89]">Twitter:</strong> <a href={result.twitter_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{result.twitter_url}</a></li>
                )}
                {result.facebook_url && (
                    <li><strong className="text-[#3A3D89]">Facebook:</strong> <a href={result.facebook_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{result.facebook_url}</a></li>
                )}
            </ul>
        </div>
    )
}

export default RenderOpenai
