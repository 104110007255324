import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

import WbIridescentOutlinedIcon from '@mui/icons-material/WbIridescentOutlined';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';

import NavigateBreadCrumbs from '../../../ui/NavigateBreadCrumbs';
import Folder from '../../../ui/Folder'
import '../../../styles/table.css'

const ResultList = () => {
    const [ids, setIds] = useState('')
    const [jobId, setJob] = useState('')
    return (
        <div className='bg-white shadow rounded'>
            <div className='py-1 px-3'>
                <NavigateBreadCrumbs>
                    <Link to='/' >Home</Link>
                    <span>Result</span>
                </NavigateBreadCrumbs>
                <Folder label='Result' icon={<WbIridescentOutlinedIcon color='#3D3A89' />} />
            </div>

            <hr />

            <div className='min-h-[75vh] mt-2 px-4'>
                <div className='flex space-x-4'>
                    <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}>
                        <InputBase
                            name='scan'
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Scan Code"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            value={ids}
                            onChange={(event) => setIds(event.target.value)}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Link to={`/result/scan/${ids}`}>
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Link>
                    </Paper>

                    <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}>
                        <InputBase
                            name='job'
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Job Id"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            value={jobId}
                            onChange={(event) => setJob(event.target.value)}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Link to={`/result/job/${jobId}`}>
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Link>
                    </Paper>


                </div>
            </div>
        </div>
    )
}

export default ResultList