import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import LoadingSpinner from '../../ui/LoadingSpinner'
import Navbar from './Navbar'
import SidebarDrawer from './SidebarDrawer'
import { Outlet } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

const Index = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showData, setShowData] = useState(true); // add here false right now its only for startup

    // add here login validation and setShowData true if user is valid

    useEffect(() => {

    }, []);
    return (
        <div>
            <Toaster position='top-center' reverseOrder={false} />
            {loading && <LoadingSpinner />}
            {showData && (<div className='bg-gray-100'>
                <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                <div className='flex mx-3'>
                    <SidebarDrawer isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                    <div className='mt-2 py-2 w-full min-h-screen'>
                        <Outlet />
                    </div>
                </div>
                <Footer />
            </div>)}
        </div>
    )
}

export default Index