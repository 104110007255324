import React from 'react';

const ReportPage = ({ nameTarget }) => {
    if (!nameTarget) {
        return null;
    }
    const startDate = new Date(nameTarget.startDate);
    const formattedStartDate = startDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
    });

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white p-8">
            <div className="flex flex-col items-center justify-center mb-12">
                {nameTarget.reportSelection === 'Securityium'
                    ? <img src="/images/securityium.svg" alt="SecureIt Logo" className="w-40 mb-6" />
                    : <img src="/images/secureit.png" alt="SecureIt Logo" className="w-40 mb-6" />
                }
                <h1 className="text-4xl font-bold text-[#3A3D89] mb-4">
                Attack Surface Scan Report
                </h1>
                <p className="text-lg text-gray-600">{nameTarget.companyName} | {formattedStartDate}</p>
            </div>

            <div className="bg-[#3A3D89] text-white p-6 rounded-lg shadow-lg max-w-2xl">
                <p className="text-center">
                    The report contains <span className="font-semibold">Confidential Information</span> and is provided to {nameTarget.companyName || 'hello'} as a deliverable for the {formattedStartDate} consulting engagement. The purpose of this report is to provide results of testing at a specific point in time against a specific identified target environment. Authorized recipients of this report agree that, before reviewing this report, they will not circulate or misuse the information contained in this report.
                </p>
            </div>
        </div>
    );
}

export default ReportPage;
