import React, { useEffect, useState } from 'react';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TableHead } from '@mui/material';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import secureApiFetch from '../../services/secureFetchApi';
import CopyText from '../../feature/CopyText';

const QuickDetails = () => {
    const [scanType, setScanType] = useState([]);
    const [formData, setFormData] = useState({
        target_list: [],
        scan_type: ''
    });
    const [submittedJobs, setSubmittedJobs] = useState(() => {
        const savedJobs = localStorage.getItem('submittedJobs');
        return savedJobs ? JSON.parse(savedJobs) : [];
    });

    useEffect(() => {
        localStorage.setItem('submittedJobs', JSON.stringify(submittedJobs));
    }, [submittedJobs]);

    const getScanType = () => {
        secureApiFetch(`/api/v1/service`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const targetTypes = [...new Set(data.map((item) => item.scan_type))];
                setScanType(targetTypes);
            })
            .catch((error) => {
                toast.error('Failed to fetch scan types');
                console.error('Error fetching scan types:', error.message);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'target_list') {
            setFormData({ ...formData, [name]: value.split(',').map(item => item.trim()) });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        secureApiFetch(`/api/v1/quick_scan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Failed to submit form');
                }
                toast.success('Form submitted successfully');
                return res.json();
            })
            .then((data) => {
                const newJob = {
                    target_list: formData.target_list,
                    scan_type: formData.scan_type,
                    job_id: data.job_id
                };
                setSubmittedJobs((prevJobs) => {
                    const updatedJobs = [...prevJobs, newJob];
                    localStorage.setItem('submittedJobs', JSON.stringify(updatedJobs));
                    return updatedJobs;
                });
                setFormData({
                    target_list: [],
                    scan_type: ''
                });
            })
            .catch((error) => {
                toast.error('Failed to submit form');
                console.error('Error submitting form:', error.message);
            });
    };

    const handleDelete = (jobId) => {
        const updatedJobs = submittedJobs.filter(job => job.job_id !== jobId);
        setSubmittedJobs(updatedJobs);
        toast.success('Job deleted successfully');
    };

    useEffect(() => {
        getScanType();
    }, []);

    return (
        <div className="p-4">
            <Box component="form" onSubmit={handleSubmit} autoComplete="off" className="space-y-4">
                <TextField
                    fullWidth
                    id="target_list"
                    name="target_list"
                    value={formData.target_list}
                    label="Target List"
                    variant="outlined"
                    onChange={handleChange}
                />

                <FormControl fullWidth>
                    <InputLabel>Scan Type</InputLabel>
                    <Select name="scan_type" label='Scan Type' value={formData.scan_type} onChange={handleChange} required>
                        {scanType && scanType.map((scan) => (
                            <MenuItem key={scan} value={scan}>{scan}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button type="submit" variant="contained" color="customColor" className="mt-4">
                    Submit
                </Button>
            </Box>
            {submittedJobs && (
                <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '4px' }}>
                    <TableContainer>
                        <Table className="table-container">
                            <TableHead className="table-heading text-left">
                                <TableRow>
                                    <TableCell align='left'> Job Id </TableCell>
                                    <TableCell align='left'> Target List </TableCell>
                                    <TableCell align='left'> Scan Type </TableCell>
                                    <TableCell align='center'> View Result</TableCell>
                                    <TableCell align='center'> Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {submittedJobs.map((item, index) => (
                                    <TableRow key={index} tabIndex={-1}>
                                        <TableCell>
                                            {item.job_id} <CopyText text={item.job_id} message={'Job Id copied!'} />
                                        </TableCell>
                                        <TableCell>{item.target_list.join(', ')}</TableCell>
                                        <TableCell>{item.scan_type}</TableCell>
                                        <TableCell align='center'>
                                            <Link to={`/result/job/${item.job_id}`}>
                                                <Button variant="contained" color="customColor" size="small">
                                                    View
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <IconButton onClick={() => handleDelete(item.job_id)}>
                                                <DeleteIcon className='text-red-600' fontSize='small' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}
        </div>
    );
};

export default QuickDetails;
