import React, { useState } from 'react';
import { IconButton } from '@mui/material';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import toast from 'react-hot-toast';

const CopyText = ({ text, message }) => {
    const [clicked, setClicked] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(text);
        setClicked(true);
        setTimeout(() => setClicked(false), 150);
        toast.success(`${message}`)
    };

    return (
        <span className="relative inline-block">
            <IconButton
                onClick={handleCopy}
                className={`transition transform duration-200 ${clicked ? 'scale-75 bg-gray-200' : ''}`}
            >
                <ContentCopyOutlinedIcon className='text-gray-600' fontSize='small' />
            </IconButton>
        </span>
    );
};

export default CopyText;
