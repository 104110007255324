import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast'
import { TableHead } from '@mui/material';

import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import DvrIcon from '@mui/icons-material/Dvr';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';

import NavigateBreadCrumbs from '../../../ui/NavigateBreadCrumbs';
import secureApiFetch from '../../../services/secureFetchApi';
import DotLoader from '../../../styles/DotLoader';
import Folder from '../../../ui/Folder'
import '../../../styles/table.css'
import Renders3scanner from './scanners/Renders3scanner';
import S3scannerjobDetail from './renderers/S3scannerjobDetail';
import HoleheJobIdDetails from './renderers/HoleheJobIdDetails';
import ShodanResult from './renderers/ShodanResult';
import Webvuln_finderJobDetails from './renderers/Webvuln_finderJobDetails';
import WhoisData from './renderers/WhoisData';


const JobDetails = () => {
    const [loading, setLoading] = useState(false)
    const [jobDetails, setJobDetails] = useState({})
    const { job_id } = useParams()

    const getDetails = () => {
        setLoading(true)
        secureApiFetch(`/api/v1/result/job_id/${job_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access': 'application/json'
            }
        })
            .then((res) => {
                if (res.status === 204) {
                    setJobDetails({});
                    setLoading(false);
                    return null;
                }
                return res.json()
            })
            .then((data) => {
                setJobDetails(data || {})
                setLoading(false)
            })
            .catch((error) => {
                console.error('Failed to retrieve data for this ID.', error);
                setLoading(false);
                toast.error('Failed to retrieve data for this ID.');
            })
    }

    const renderValue = (value) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
            return (
                <div>
                    {Object.entries(value).map(([subKey, subValue]) => (
                        <div key={subKey} className='flex'>
                            <strong>{subKey}</strong>: <p>{renderValue(subValue)}</p>
                        </div>
                    ))}
                </div>
            );
        }
        return value;
    };
    const renderSslscan = (data) => {
        if (Array.isArray(data)) {
            return (
                <div className="space-y-3">
                    {data.map((item, index) => (
                        <div key={index} className="">
                            {Object.entries(item).map(([key, value]) => (
                                <div key={key} className="flex">
                                    <span className="text-[#3A3D89] font-semibold">{key}:</span>
                                    <div className=" text-gray-700">
                                        {Array.isArray(value) ? (
                                            <ul className="list-disc ml-4 space-y-1">
                                                {value.map((subItem, subIndex) => (
                                                    <li key={subIndex} className="leading-relaxed">
                                                        {renderSslscan(subItem)}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : typeof value === 'object' && value !== null ? (
                                            <div className="ml-4 space-y-1">
                                                {Object.entries(value).map(([subKey, subValue]) => (
                                                    <div key={subKey} className="flex mb-1">
                                                        <span className="text-[#3A3D89] font-semibold">{subKey}:</span>
                                                        <div className="ml-2 text-gray-700">
                                                            {renderSslscan(subValue)}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <span>{value}</span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            );
        } else if (typeof data === 'object' && data !== null) {
            return (
                <div className="space-y-4">
                    {Object.entries(data).map(([key, value]) => (
                        <div key={key} className="flex mb-2">
                            <span className="text-[#3A3D89] font-semibold">{key}:</span>
                            <div className="ml-2 text-gray-700">
                                {Array.isArray(value) ? (
                                    <ul className="list-disc ml-4 space-y-1">
                                        {value.map((subItem, subIndex) => (
                                            <li key={subIndex} className="leading-relaxed">
                                                {renderSslscan(subItem)}
                                            </li>
                                        ))}
                                    </ul>
                                ) : typeof value === 'object' && value !== null ? (
                                    <div className="ml-4 space-y-1">
                                        {Object.entries(value).map(([subKey, subValue]) => (
                                            <div key={subKey} className="flex mb-1">
                                                <span className="text-[#3A3D89] font-semibold">{subKey}:</span>
                                                <div className="ml-2 text-gray-700">
                                                    {renderSslscan(subValue)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <span>{value}</span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return <div className="text-gray-700">{data}</div>;
        }
    };

    useEffect(() => {
        if (job_id) {
            getDetails()
        }
    }, [job_id])
    return (
        <div className='bg-white shadow rounded'>
            <div className='py-1 px-3'>
                <NavigateBreadCrumbs>
                    <Link to='/' >Home</Link>
                    <Link to="/schedulescan">Schedule Scan</Link>
                    <span>Job Id Details</span>
                </NavigateBreadCrumbs>
                <Folder label='Job Id Details' icon={<DvrIcon color='#3D3A89' />} />
            </div>
            <hr />
            <div id="table-to-print" className='p-4 min-h-[75vh]'>
                <Paper sx={{ width: '100%' }}>
                    <TableContainer component={Paper}>
                        <Table className="table-container">
                            <TableHead className="table-heading text-left">
                                <TableRow>
                                    <TableCell align="left">Classification</TableCell>
                                    <TableCell align="left">Output Type</TableCell>
                                    <TableCell align="center">Next Scan</TableCell>
                                    <TableCell >Vulnerabilty Name</TableCell>
                                    <TableCell align="left">Result</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                                {loading && (
                                    <TableCell colSpan={11} sx={{ padding: '1.5rem 0rem' }}>
                                        <DotLoader />
                                    </TableCell>
                                )}
                                {!loading && Object.keys(jobDetails).length === 0 && (
                                    <TableCell colSpan={11} sx={{ padding: '1.5rem 0rem', textAlign: 'center' }}>
                                        <span className='text-red-600 font-bold text-xl'>
                                            Nothing to see here... yet.
                                        </span>
                                    </TableCell>
                                )}
                                {!loading && Object.keys(jobDetails).map(domain => {
                                    return (
                                        <React.Fragment key={domain}>
                                            <TableRow className='data-row text-left'>
                                                <TableCell colSpan={5} className='td bg-gray-100'>
                                                    <strong>Target: {domain}</strong>
                                                </TableCell>
                                            </TableRow>
                                            {Object.keys(jobDetails[domain]).map(service => {
                                                const serviceData = jobDetails[domain][service];
                                                return (
                                                    <React.Fragment key={`${domain}-${service}`}>
                                                        <TableRow className='data-row text-left'>
                                                            <TableCell colSpan={5} className='td bg-gray-100'>Scanner: {service}</TableCell>
                                                        </TableRow>
                                                        {serviceData.map((item, index) => (
                                                            <TableRow key={`${domain}-${service}-${index}`} className="data-row text-left">
                                                                <TableCell >{item.classification}</TableCell>
                                                                <TableCell >{item.output_type}</TableCell>
                                                                <TableCell >{item.next_scan}</TableCell>
                                                                <TableCell >{item.vulnerabilty_name}</TableCell>
                                                                <TableCell>
                                                                    {service === 'webvuln_finder' && <Webvuln_finderJobDetails result={item.result} />}
                                                                    {service === 'sslscan' && renderSslscan(item.result)}
                                                                    {service === 'whois' && <WhoisData result={item.result} />}
                                                                    {service === 's3scanner' && <S3scannerjobDetail result={item.result} />}
                                                                    {service === 'shodan' && <ShodanResult result={item.result} />}
                                                                    {service === 'holehe' && <HoleheJobIdDetails result={item.result} />}
                                                                    {service !== 'shodan' && service !== 'holehe' && service !== 's3scanner' && service !== 'webvuln_finder' && service !== 'sslscan' && service !== 'whois' && (
                                                                        <div>
                                                                            {Array.isArray(item.result) ? (
                                                                                item.result.map((resultItem, idx) => (
                                                                                    <div key={idx}>
                                                                                        {Object.entries(resultItem).map(([key, value]) => (
                                                                                            <div key={key} className='flex'>
                                                                                                <strong>{key}</strong>: <p>{renderValue(value)}</p>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div>{item.result}</div>
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div>
    )
}

export default JobDetails