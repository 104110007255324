import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { resetSessionStorageAndRedirect } from '../../services/secureFetchApi';
import Auth from '../../services/auth';
import AvatarApp from '../avatar/AvatarApp';

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = Auth.getLoggedInUser();
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (event) => {
        event.preventDefault();
        resetSessionStorageAndRedirect()
    }
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <AppBar position="static" style={{ backgroundColor: 'white' }}>
            <Toolbar>
                <IconButton onClick={toggleSidebar} aria-label="menu" size="large" edge="start" sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    <img src={`https://clients.prosecurity.work/layout/images/logo.svg`} alt="logo" style={{ width: 150 }} />
                </Typography>

                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    sx={{ color: '#282560', backgroundColor: 'ButtonFace', p: '5px' }}
                >
                    {user &&
                        <AvatarApp email={user.email} size="30px" />
                    }
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {user &&
                        <Link to={`users/${user.id}`} >
                            <MenuItem>Profile</MenuItem>
                        </Link>
                    }
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};
export default Navbar;
