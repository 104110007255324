import { Route } from "react-router-dom";
import User from "./User";
import CreateUser from "./create";
import UserDetails from "./details";
import EditUser from "./edit";
const UserRoute = [
    <Route path={`/users`} element={<User />} />,
    <Route path={`/create`} element={<CreateUser />} />,
    <Route path={`/users/:id`} element={<UserDetails />} />,
    <Route path={`/users/edit/:id`} element={<EditUser />} />,
]
export default UserRoute;