import React from 'react'

export const RenderSslscan = ({ data }) => {
    if (Array.isArray(data)) {
        return (
            <div className="space-y-3">
                {data.map((item, index) => (
                    <div key={index} className="">
                        {Object.entries(item).map(([key, value]) => (
                            <div key={key} className="flex">
                                <span className="text-[#3A3D89] font-semibold">{key}:</span>
                                <div className=" text-gray-700">
                                    {Array.isArray(value) ? (
                                        <ul className="list-disc ml-4 space-y-1">
                                            {value.map((subItem, subIndex) => (
                                                <li key={subIndex} className="leading-relaxed">
                                                    {RenderSslscan(subItem)}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : typeof value === 'object' && value !== null ? (
                                        <div className="ml-4 space-y-1">
                                            {Object.entries(value).map(([subKey, subValue]) => (
                                                <div key={subKey} className="flex mb-1">
                                                    <span className="text-[#3A3D89] font-semibold">{subKey}:</span>
                                                    <div className="ml-2 text-gray-700">
                                                        {RenderSslscan(subValue)}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <span>{value}</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    } else if (typeof data === 'object' && data !== null) {
        return (
            <div className="space-y-4">
                {Object.entries(data).map(([key, value]) => (
                    <div key={key} className="flex mb-2">
                        <span className="text-[#3A3D89] font-semibold">{key}:</span>
                        <div className="ml-2 text-gray-700">
                            {Array.isArray(value) ? (
                                <ul className="list-disc ml-4 space-y-1">
                                    {value.map((subItem, subIndex) => (
                                        <li key={subIndex} className="leading-relaxed">
                                            {RenderSslscan(subItem)}
                                        </li>
                                    ))}
                                </ul>
                            ) : typeof value === 'object' && value !== null ? (
                                <div className="ml-4 space-y-1">
                                    {Object.entries(value).map(([subKey, subValue]) => (
                                        <div key={subKey} className="flex mb-1">
                                            <span className="text-[#3A3D89] font-semibold">{subKey}:</span>
                                            <div className="ml-2 text-gray-700">
                                                {RenderSslscan(subValue)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <span>{value}</span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return <div className="text-gray-700">{data}</div>;
    }
}
