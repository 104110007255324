import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import DvrIcon from '@mui/icons-material/Dvr';
import NavigateBreadCrumbs from '../../../ui/NavigateBreadCrumbs';
import Folder from '../../../ui/Folder';
import secureApiFetch from '../../../services/secureFetchApi';
import QueueTable from './table';

const QueueStatusList = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getQueueData = () => {
    setLoading(true);
    secureApiFetch(`/api/v1/queue-status`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((res) => {
        if (res.status === 204) {
          setLoading(false);
          setData([]);
          return;
        }
        return res.json();
      })
      .then((data) => {
        setLoading(false);
        if (data) {
          setData(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Client ID Not Found:', error);
      });
  }
  useEffect(() => {
    getQueueData()
  }, [])

  return (
    <div className='bg-white  shadow rounded'>
      <div className='py-1 px-3'>
        <NavigateBreadCrumbs>
          <Link to='/'>Home</Link>
          <span>Queue Status</span>
        </NavigateBreadCrumbs>
        <Folder label='Queue Status' icon={<DvrIcon color='#3D3A89' />} />
      </div>
      <hr />
      <div className='min-h-[75vh] mt-2'>
        <QueueTable data={data} loading={loading} />
      </div>
    </div>
  )
}

export default QueueStatusList