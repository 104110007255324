import React from 'react'

const Note = () => {
    return (
        <div className="px-4 pt-1 bg-white h-screen">
            <div className="flex flex-col items-center mb-8">
                <label className="text-2xl font-semibold text-[#3A3D89] mr-4">
                Attack Surface Scan Report
                </label>
                {/* <div className='flex space-x-2'>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                </div> */}
            </div>
            <div className='p-4'>
                <h1 className="text-2xl font-bold text-white bg-[#3A3D89] mb-4 py-1 px-2">4. Assessor's Note</h1>
                    <div className="text-lg note-content text-gray-700 leading-relaxed">
                        The assessor attempted to discover vulnerabilities at the network layer using automated as well as manual techniques. The assessor was not permitted for intrusive and DoS exploits as assessment was performed on business-critical production machine & network.
                    </div>
            </div>
        </div>
    )
}

export default Note