import { Route } from "react-router-dom";
import ServicesList from "./list";
import CreateService from "./create";
import EditService from "./edit";

const ServicesRoute = [
    <Route path={`/services`} element={<ServicesList />} />,
    <Route path={`/services/create`} element={<CreateService />} />,
    <Route path={`/services/edit/:id`} element={<EditService />} />,
]
export default ServicesRoute;
