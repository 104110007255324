import React, { useEffect, useState } from 'react';

const Overview = () => {
    const [goals, setGoals] = useState('');
    const [otherConsiderations, setOtherConsiderations] = useState('');
    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

    useEffect(() => {
        if (companyInfo) {
            setGoals(companyInfo.goals || '');
            setOtherConsiderations(companyInfo.otherConsiderations || '');
        }
    }, [companyInfo]);

    return (
        <div className="px-4 pt-1 bg-white h-screen">
            <div className="flex flex-col items-center mb-8">
                <label className="text-2xl font-semibold text-[#3A3D89] mr-4">
                    Attack Surface Scan Report
                </label>
                {/* <div className='flex space-x-2'>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                </div> */}
            </div>
            <div className="space-y-4">
                <h1 className="text-2xl font-bold text-white bg-[#3A3D89] mb-4 py-1 px-2">2.Overview</h1>
                <div>
                    <h2 className="text-xl font-semibold text-[#3A3D89] mb-2">Goals and Objective</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        {goals || 'No goals and objective provided.'}
                    </p>
                </div>

                <div>
                    <h2 className="text-xl font-semibold text-[#3A3D89] mb-2">Other Considerations</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        {otherConsiderations || 'No other considerations provided.'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Overview;
