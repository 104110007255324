const formatString = (key) => {
    switch (key) {
        case 'subfinder':
            return 'Subdomain List';
        case 'securityium_dns_resolver':
            return 'DNS Records';
        case 'theHarvester':
            return 'Publically Found Email';
        case 'findcdn':
            return 'CDN List';
        case 'dehashed':
            return 'Compromised Email & Domain List';
        case 'certsh':
            return 'Subdomain List';
        case 'whois':
            return 'WHOIS Information';
        case 'sslscan':
            return 'SSL Certificate Details';
        case 'subzy':
            return 'Subdomain Takeover Vulnerability';
        case 'shodan':
            return 'Host Information';
        case 'openai':
            return 'Company Details';
        case 'holehe':
            return 'Email & Social Information';
        case 'naabu':
            return 'Host & Open Port List';
        case 's3scanner':
            return 'Open S3 Buckets';
        default:
            return key;
    }
};

const pataString = (key) => {
    switch (key) {
        case 'subfinder':
            return 'Identifies and lists subdomains associated with a given domain.';
        case 'securityium_dns_resolver':
            return 'Retrieves DNS records for the specified domain.';
        case 'theHarvester':
            return 'Collects publicly available email addresses from various sources.';
        case 'findcdn':
            return 'Lists the Content Delivery Networks (CDNs) associated with a domain.';
        case 'dehashed':
            return 'Finds compromised emails and domains from public breach databases.';
        case 'certsh':
            return 'Fetches a list of subdomains based on certificate transparency logs.';
        case 'whois':
            return 'Provides WHOIS information, including domain ownership details.';
        case 'sslscan':
            return 'Analyzes SSL certificates to provide detailed information.';
        case 'subzy':
            return 'Checks for potential subdomain takeover vulnerabilities.';
        case 'shodan':
            return 'Gathers host information by querying the Shodan search engine.';
        case 'openai':
            return 'Generates a detailed description of the company using OpenAI.';
        case 'holehe':
            return 'Finds email addresses and associated social media information.';
        case 'naabu':
            return 'Scans for open ports and services running on the host.';
        case 's3scanner':
            return 'Identifies open and misconfigured Amazon S3 buckets.';
        case 'webvuln_finder':
            return 'It identifies vulnerabilities and issues on the given target.';
        case 'shcheck':
            return 'It identifies security headers on target and gave missing security headers.';
        case 'rustscan':
            return `It idenfies open port on target's ip.`;
        case 'crlfsuite':
            return 'It detection and try to exploit CRLF injection.';
        default:
            return `No description available for ${key}.`;
    }
};

export { formatString, pataString };