import React, { useState } from 'react'
import { TextField, Button, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import Folder from '../../ui/Folder';
import secureApiFetch from '../../services/secureFetchApi';
import toast from 'react-hot-toast';

const CreateUser = () => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        designation: '',
        user_role: '',
    });

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureApiFetch(`/api/v1/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Failed to submit form');
                }
                toast.success('User Created successfully');
                navigate('/users')
            })
            .catch((error) => {
                toast.error('Failed to Create User');
                console.error('Error submitting form:', error.message);
            });
    };

    return (
        <div className="bg-white shadow rounded">
            <div className="flex justify-between items-center py-1 px-3">
                <div>
                    <NavigateBreadCrumbs>
                        <Link to='/'>Home</Link>
                        <Link to="/users">Users</Link>
                        <span>Create User</span>
                    </NavigateBreadCrumbs>
                    <Folder label="Create User" icon={<PersonAddAltRoundedIcon color="#3D3A89" />} />
                </div>
            </div>
            <hr />
            <div className="min-h-[80vh]">
                <section>
                    {loading && (
                        <div className="flex justify-center">
                            <p>Loading...</p>
                        </div>
                    )}
                    <div className="flex justify-center px-4 py-10 w-full">
                        <form
                            onSubmit={handleSubmit}
                            className="space-y-5 w-full max-w-md"
                        >
                            <div>
                                <TextField
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    required
                                    variant="outlined"
                                    InputProps={{
                                        className: 'rounded-md bg-transparent text-sm focus:ring-1 focus:ring-gray-400',
                                    }}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    variant="outlined"
                                    InputProps={{
                                        className: 'rounded-md bg-transparent text-sm focus:ring-1 focus:ring-gray-400',
                                    }}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    variant="outlined"
                                    InputProps={{
                                        className: 'rounded-md bg-transparent text-sm focus:ring-1 focus:ring-gray-400',
                                    }}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    id="designation"
                                    label="Designation"
                                    name="designation"
                                    value={formData.designation}
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                        className: 'rounded-md bg-transparent text-sm focus:ring-1 focus:ring-gray-400',
                                    }}
                                />
                            </div>

                            <div>
                                <FormControl fullWidth>
                                    <InputLabel id="user-role-label">User Role</InputLabel>
                                    <Select
                                        labelId="user-role-label"
                                        id="user_role"
                                        name="user_role"
                                        label='User Role'
                                        value={formData.user_role}
                                        onChange={handleChange}
                                        variant="outlined"
                                    >
                                        <MenuItem value="user">User</MenuItem>
                                        <MenuItem value="admin">Admin</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            {error && (
                                <div>
                                    <p className="text-red-500">Error: {error}</p>
                                </div>
                            )}

                            <div>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    style={{ backgroundColor: '#282560', color: '#fff' }}
                                    className="inline-flex justify-center rounded-md px-3.5 py-2.5 font-semibold leading-7 hover:bg-black/80"
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default CreateUser