import React from 'react'
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderCopyRoundedIcon from '@mui/icons-material/FolderCopyRounded';


const Folder = ({ label, icon }) => {
  return (
    <div className='m-1 flex items-center space-x-2'>
      {icon && (
        <span className='text-[#3D3A89] bg-[#DFDFEE] p-2  rounded-full'>
          {icon}
        </span>
      )}

      <span className='text-sm font-semibold'>{label}</span>
    </div>
  )
}

export default Folder