import React, { useEffect, useState } from 'react'

const KeyAssesment = () => {
    const [keyAssesment, setKeyAssesment] = useState('');
    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

    useEffect(() => {
        if (companyInfo) {
            setKeyAssesment(companyInfo.keyAssesment);
        }
    }, [companyInfo]);

    return (
        <div className="px-4 pt-1 bg-white">
            <div className="flex flex-col items-center mb-8">
                <label className="text-2xl font-semibold text-[#3A3D89] mr-4">
                    Attack Surface Scan Report
                </label>
                {/* <div className='flex space-x-2'>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                </div> */}
            </div>
            <div className="space-y-4">
                <h1 className="text-2xl font-bold text-white bg-[#3A3D89] mb-4 py-1 px-2">3.Key Assessment Areas</h1>
                <div>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        {keyAssesment || 'No Key Assesment provided.'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default KeyAssesment