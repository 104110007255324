import React from 'react'

const RenderWhoisData = ({ result }) => {
    return (
        <div className="bg-[#F9F9F9]">
            {result?.query && (
                <p className="text-md">
                    <span className="font-semibold text-[#3A3D89]">Query:</span> {result.query}
                </p>
            )}
            {result?.website && (
                <p className="text-md">
                    <span className="font-semibold text-[#3A3D89]">Website:</span> {result.website}
                </p>
            )}
            {result?.status && (
                <p className="text-md">
                    <span className="font-semibold text-[#3A3D89]">Status:</span> {result.status}
                </p>
            )}
            {result?.error_desc && (
                <p className="text-md">
                    <span className="font-semibold text-[#3A3D89]">error_desc:</span> {result.error_desc}
                </p>
            )}

            {result?.popularity && (
                <div className="mt-4">
                    <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Popularity:</h4>
                    {result.popularity.rank && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Rank:</span> {result.popularity.rank}
                        </p>
                    )}
                    {result.popularity.visitors && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Visitors:</span> {result.popularity.visitors}
                        </p>
                    )}
                    {result.popularity.image && (
                        <img src={result.popularity.image} alt="Popularity" className="my-2 max-w-xs" />
                    )}
                    {result.popularity.text && (
                        <p className="text-md">{result.popularity.text}</p>
                    )}
                </div>
            )}

            {result?.ip_address && (
                <p className="text-md mt-4">
                    <span className="font-semibold text-[#3A3D89]">IP Address:</span> {result.ip_address}
                </p>
            )}
            {result?.ipv6_address && (
                <p className="text-md">
                    <span className="font-semibold text-[#3A3D89]">IPv6 Address:</span> {result.ipv6_address}
                </p>
            )}

            {result?.location && (
                <div className="mt-4">
                    <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Location:</h4>
                    {result.location.countryName && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Country:</span> {result.location.countryName}
                            {result.location.countryIcon && (
                                <img src={result.location.countryIcon} alt={result.location.countryName} className="inline-block ml-2 h-4 w-4 align-middle" />
                            )}
                        </p>
                    )}
                </div>
            )}

            {result?.reverse_dns && (
                <div className="mt-4">
                    <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Reverse DNS:</h4>
                    {result.reverse_dns.host && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Host:</span> {result.reverse_dns.host}
                        </p>
                    )}
                </div>
            )}

            {result?.owners?.owner && (
                <div className="mt-4">
                    <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Owners:</h4>
                    {result.owners.owner.ownerName && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Owner Name:</span> {result.owners.owner.ownerName}
                        </p>
                    )}
                    {result.owners.owner.provider && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Provider:</span> {result.owners.owner.provider}
                        </p>
                    )}
                    {result.owners.owner.address && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Address:</span> {result.owners.owner.address}
                        </p>
                    )}
                    {result.owners.owner.countryName && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Country:</span> {result.owners.owner.countryName}
                            {result.owners.owner.countryIcon && (
                                <img src={result.owners.owner.countryIcon} alt={result.owners.owner.countryName} className="inline-block ml-2 h-4 w-4 align-middle" />
                            )}
                        </p>
                    )}
                    {result.owners.owner.website && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Website:</span> {result.owners.owner.website}
                        </p>
                    )}
                    {result.owners.owner.phone && (
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Phone:</span> {result.owners.owner.phone}
                        </p>
                    )}
                    {result.owners.owner.link && (
                        <a href={result.owners.owner.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                            More details
                        </a>
                    )}
                </div>
            )}

            {result?.dns?.length > 0 && (
                <div className="mt-4">
                    <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">DNS Records:</h4>
                    {result.dns.map((dnsRecord, index) => (
                        <div key={index} className="mb-2">
                            {dnsRecord.nameserver && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">Nameserver:</span> {dnsRecord.nameserver}
                                </p>
                            )}
                            {dnsRecord.ip_address && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">IP Address:</span> {dnsRecord.ip_address}
                                </p>
                            )}
                            {dnsRecord.countryName && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">Country:</span> {dnsRecord.countryName}
                                    {dnsRecord.countryIcon && (
                                        <img src={dnsRecord.countryIcon} alt={dnsRecord.countryName} className="inline-block ml-2 h-4 w-4 align-middle" />
                                    )}
                                </p>
                            )}
                            {dnsRecord.sites && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">Sites:</span> {dnsRecord.sites}
                                </p>
                            )}
                            {dnsRecord.topSites && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">Top Sites:</span> {dnsRecord.topSites}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {result?.ip_change_history?.length > 0 && (
                <div className="mt-4">
                    <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">IP Change History:</h4>
                    {result.ip_change_history.map((history, index) => (
                        <div key={index} className="mb-2">
                            {history.ip_address && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">IP Address:</span> {history.ip_address}
                                </p>
                            )}
                            {history.host && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">Host:</span> {history.host}
                                </p>
                            )}
                            {history.date_when_website_was_using_ip && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">Date When Website Was Using IP:</span> {history.date_when_website_was_using_ip}
                                </p>
                            )}
                            {history.date_when_found_that_website_changed_ip && (
                                <p className="text-md">
                                    <span className="font-semibold text-[#3A3D89]">Date When Found That Website Changed IP:</span> {history.date_when_found_that_website_changed_ip}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default RenderWhoisData