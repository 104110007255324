import React from 'react'
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DotLoader from '../../styles/DotLoader';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import secureApiFetch from '../../services/secureFetchApi';
import toast from 'react-hot-toast';
import AvatarApp from '../avatar/AvatarApp';

const UserTable = ({ loading, usersData, getUsers }) => {

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this record?');
    if (confirmDelete) {
      secureApiFetch(`/api/v1/user/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => {
          getUsers()
          if (res.ok) {
            toast.success('User deleted successfully!');
          } else {
            toast.error('Failed to delete User. Please try again.');
          }
        })
        .catch((error) => {
          toast.error('An error occurred while deleting the User.');
          console.error('Error deleting User:', error);
        });
    }
  };
  return (
    <div>
      <Paper sx={{ width: '100%', marginTop: 2 }}>
        <TableContainer component={Paper}>
          <Table className="table-container">
            <TableHead className="table-heading">
              <TableRow>
                <TableCell align="left">Username</TableCell>
                <TableCell align="center">Designation</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">User Role</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (<tr>
                <TableCell colSpan={11} sx={{ padding: '1.5rem 0rem' }}>
                  <DotLoader />
                </TableCell>
              </tr>)}
              {!loading && usersData.length === 0 && (
                <tr>
                  <td colSpan={10} align="left " className='py-3 font-bold text-xl text-red-600'>
                    Nothing to see here... yet.
                  </td>
                </tr>
              )}
              {!loading && usersData.length > 0 && usersData.length !== 0 && usersData.map((item, index) => (
                <TableRow key={index} className="data-row text-left py-2">
                  <TableCell align="left" sx={{ paddingY: '1rem' }}>
                    <span className='flex items-center space-x-2'>
                      <AvatarApp email={item.email} size="35px" />
                      <Link to={`/users/${item.user_id}`} >
                        {item.username}
                      </Link>
                    </span>
                  </TableCell>
                  <TableCell align="center"> {item.designation} </TableCell>
                  <TableCell align="center"> {item.email} </TableCell>
                  <TableCell align="center"> {item.user_role} </TableCell>
                  <TableCell align="center">
                    <Link to={`/users/edit/${item.user_id}`}>
                      <EditIcon className="text-[#3D3A89] " fontSize="small" />
                    </Link>
                    <IconButton onClick={() => handleDelete(item.user_id)}>
                      <DeleteIcon className='text-red-600' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default UserTable