import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import Select from '@mui/material/Select';

import secureApiFetch from '../../services/secureFetchApi';
import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import Folder from '../../ui/Folder';
import Cron from 'react-js-cron';
import toast from 'react-hot-toast';

const ScheduleEdit = () => {
    const [formData, setFormData] = useState({
        target_type: "",
        cron: "",
        schedule_count: 1,
        status: ""
    });

    const [valueCron, setValueCron] = useState(formData.cron)
    const { scan_id } = useParams()
    const [targetType, setTargetType] = useState([])
    const navigate = useNavigate()

    const getData = () => {
        secureApiFetch(`/api/v1/schedule_scan/${scan_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setFormData(data)
            })
            .catch((error) => {
                console.error('Failed to get data', error)
            })
    }

    const getSerivceTarget = () => {
        secureApiFetch(`/api/v1/service`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const targetTypes = [...new Set(data.map((item) => item.target_type))];
                setTargetType(targetTypes)
            })
            .catch((error) => {
                toast.error('Failed to submit form');
                console.error('Error submitting form:', error.message);
            });
    }

    useEffect(() => {
        setFormData({ ...formData, cron: valueCron });
    }, [formData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const addForm = {
            "target_type": formData.target_type,
            "cron": formData.cron,
            "schedule_count": formData.schedule_count,
            "status": formData.status
        }
        try {
            const response = await secureApiFetch(`/api/v1/schedule_scan/${scan_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Failed to schedule scan. Please try again.');
            }
            navigate('/schedulescan')
            toast.success('Schedule updated successfully');
        } catch (error) {
            console.error('Failed to schedule scan:', error);
            toast.error('Failed to schedule scan. Please try again.');
        }
    };

    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        getSerivceTarget()
    }, [])

    return (
        <div className="bg-white shadow rounded">
            <div className=' py-1 px-3'>
                <NavigateBreadCrumbs>
                    <Link to='/' >Home</Link>
                    <Link to="/schedulescan">Schedule Scan</Link>
                    <span>Edit Scan Detail</span>
                </NavigateBreadCrumbs>
                <Folder label={formData.target} icon={<TextSnippetIcon color='3A3D89' />} />
            </div>
            <hr className='my-2' />
            <form onSubmit={handleSubmit} className='min-h-[80vh] -mt-3 flex flex-col space-y-2 p-4'>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Target Type</InputLabel>
                    <Select name="target_type" labelId="demo-simple-select-label" id="demo-simple-select" value={formData.target_type} label="Target Type" onChange={handleChange}>
                        {targetType.map((target) => (
                            <MenuItem value={target}>{target}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Cron"
                    variant="outlined"
                    name="cron"
                    value={valueCron}
                />
                <Cron value={valueCron} setValue={setValueCron} />

                <TextField
                    type="number"
                    label="Schedule Count"
                    variant="outlined"
                    name="schedule_count"
                    value={formData.schedule_count}
                    onChange={handleChange}
                />
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select name="status" labelId="demo-simple-select-label" id="demo-simple-select" value={formData.status} label="Status" onChange={handleChange}>
                        {formData.schedule_count === 'completed' &&
                            <MenuItem value='scheduled'> Scheduled </MenuItem>
                        }
                        {formData.schedule_count === 'completed' &&
                            <MenuItem value='canceled'> Canceled </MenuItem>
                        }
                        <MenuItem value='completed'> Completed </MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" color="customColor" type="submit">
                    Submit
                </Button>
            </form>
        </div>
    )
}

export default ScheduleEdit