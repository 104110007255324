import React from 'react'
import { Link } from 'react-router-dom';

import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import SettingDetails from './details';
import Folder from '../../ui/Folder';

const Settings = () => {
  return (
    <div className='bg-white shadow rounded'>
      <div className='py-1 px-3'>
        <NavigateBreadCrumbs>
          <Link to='/'>Home</Link>
          <span>Setting</span>
        </NavigateBreadCrumbs>
        <Folder label='Result' icon={<TuneRoundedIcon color='#3D3A89' />} />
      </div>

      <div className='min-h-[75vh]'>
        <SettingDetails />
      </div>
    </div>
  )
}

export default Settings