import * as React from 'react';
import { IconButton, TableHead } from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';

import secureApiFetch from '../../services/secureFetchApi';
import DotLoader from '../../styles/DotLoader';
import ColorBadge from '../../ui/ColorBadge';


const ServicesTable = ({ service, getData }) => {

    const handleDelete = (id) => {
        const confirm = window.confirm('Are you sure you want to delete this Service? This action cannot be undone.')
        if (confirm) {
            secureApiFetch(`/api/v1/service/${id}`, {
                method: 'Delete',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    if (res.ok) {
                        toast.success('Service deleted successfully');
                        getData()
                    } else {
                        throw new Error('Failed to delete service');
                    }
                })
                .catch((error) => {
                    toast.error('Failed to delete service');
                    console.error('Delete service error:', error);
                });
        }
    }

    return (
        <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table className="table-container">
                        <TableHead className="table-heading text-left">
                            <TableRow>
                                <TableCell align='left'> Id </TableCell>
                                <TableCell align='left'> Scan Type </TableCell>
                                <TableCell align='left'> Target Type </TableCell>
                                <TableCell align='center'> Status </TableCell>
                                <TableCell align='left'> Insert Ts </TableCell>
                                <TableCell align='left'> Update Ts </TableCell>
                                <TableCell align='center'> Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {service.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={10} align="center" sx={{ padding: '1.5rem 0rem' }}>
                                        <DotLoader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {service.map((item, index) => (
                                <TableRow hover role="checkbox" key={index} tabIndex={-1} >
                                    <TableCell> {index + 1} </TableCell>
                                    <TableCell> {item.scan_type} </TableCell>
                                    <TableCell> {item.target_type} </TableCell>
                                    <TableCell align='center'>
                                        <ColorBadge badge={item.status} />
                                    </TableCell>
                                    <TableCell> {item.insert_ts} </TableCell>
                                    <TableCell> {item.update_ts} </TableCell>
                                    <TableCell align='center'>
                                        <Link to={`/services/edit/${item.id}`} >
                                            <EditIcon className='text-[#3D3A89]' fontSize='small' />
                                        </Link>
                                        <IconButton onClick={() => handleDelete(item.id)}>
                                            <DeleteIcon className='text-red-600' fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>

    );
}

export default ServicesTable