import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DateFormat from '../../../ui/DateFormat'
import DotLoader from '../../../styles/DotLoader';

const QueueTable = ({ loading, data }) => {
    const extractJobData = (item) => {
        const key = Object.keys(item)[0];
        const jobInfo = item[key]['upcoming Job'];
        const { job_uid, scan_type, data, ts } = jobInfo;
        return { job_uid, scan_type, request_body: data.request_body, ts, totalJobs: item[key]['total jobs in queue'] };
    };

    return (
        <div className='p-4' >
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table className="table-container">
                        <TableHead className="table-heading text-left">
                            <TableRow>
                                <TableCell align="center">Scan Type</TableCell>
                                <TableCell align="center">Job ID</TableCell>
                                <TableCell align="center">Timestamp</TableCell>
                                <TableCell align="center">Total Jobs in Queue</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableCell colSpan={5} sx={{ padding: '1.5rem 0rem' }}>
                                        <DotLoader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && data.length === 0 && (
                                <TableRow>
                                <TableCell sx={{ paddingY: 4 }} colSpan={4} align="center" className='py-3 font-bold text-xl text-red-600'>
                                    Nothing to see here... yet.
                                </TableCell>
                            </TableRow>
                            )}
                            {!loading && data.length > 0 && data.map((item, index) => {
                                const { job_uid, scan_type, request_body, ts, totalJobs } = extractJobData(item);
                                return (
                                    <TableRow key={index} className="data-row text-left">
                                        <TableCell align="center" sx={{paddingY:2}} >{scan_type}</TableCell>
                                        <TableCell align="center">{job_uid}</TableCell>
                                        <TableCell align="center">
                                        <DateFormat date={ts} /> </TableCell>
                                        <TableCell align="center">{totalJobs}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export default QueueTable;
