import React from 'react'

const WhoisData = ({ result }) => {
    return (
        <div className="p-4">
            {result.map((item, index) => (
                <div key={index} className="mb-4">
                    {item.query && (
                        <p><strong>query:</strong> {item.query}</p>
                    )}
                    {item.website && (
                        <p><strong>Website:</strong> {item.website}</p>
                    )}
                    {item.status && (
                        <p><strong>Status:</strong> {item.status}</p>
                    )}
                    {item.error_desc && (
                        <p><strong>error_desc:</strong> {item.error_desc}</p>
                    )}

                    {item.popularity && (
                        <div className="mt-2">
                            <h3 className="text-md font-medium">Popularity</h3>
                            {item.popularity.rank && (
                                <p><strong>Rank:</strong> {item.popularity.rank}</p>
                            )}
                            {item.popularity.visitors && (
                                <p><strong>Visitors:</strong> {item.popularity.visitors}</p>
                            )}
                            {item.popularity.image && (
                                <img src={item.popularity.image} alt="Popularity rating" className="w-24 h-auto" />
                            )}
                            {item.popularity.text && (
                                <p>{item.popularity.text}</p>
                            )}
                        </div>
                    )}

                    {item.location && (
                        <div className="mt-2">
                            <h3 className="text-md font-medium">Location</h3>
                            {item.location.countryName && (
                                <p><strong>Country:</strong> {item.location.countryName}</p>
                            )}
                            {item.location.countryIcon && (
                                <img src={item.location.countryIcon} alt="Country flag" className="w-8 h-auto" />
                            )}
                        </div>
                    )}

                    {item.reverse_dns && item.reverse_dns.host && (
                        <div className="mt-2">
                            <h3 className="text-md font-medium">Reverse DNS</h3>
                            <p><strong>Host:</strong> {item.reverse_dns.host}</p>
                        </div>
                    )}

                    {item.owners?.owner && (
                        <div className="mt-2">
                            <h3 className="text-md font-medium">Owners</h3>
                            {item.owners.owner.ownerName && (
                                <p><strong>Owner Name:</strong> {item.owners.owner.ownerName}</p>
                            )}
                            {item.owners.owner.address && (
                                <p><strong>Address:</strong> {item.owners.owner.address}</p>
                            )}
                            {item.owners.owner.phone && (
                                <p><strong>Phone:</strong> {item.owners.owner.phone}</p>
                            )}
                            {item.owners.owner.link && (
                                <a href={item.owners.owner.link} target="_blank" rel="noopener noreferrer">More info</a>
                            )}
                        </div>
                    )}

                    {item.dns?.length > 0 && (
                        <div className="mt-2">
                            <h3 className="text-md font-medium">DNS Servers</h3>
                            {item.dns.map((dns, dnsIndex) => (
                                <div key={dnsIndex} className="mb-2">
                                    {dns.nameserver && (
                                        <p><strong>Nameserver:</strong> {dns.nameserver}</p>
                                    )}
                                    {dns.ip_address && (
                                        <p><strong>IP Address:</strong> {dns.ip_address}</p>
                                    )}
                                    {dns.countryName && (
                                        <p><strong>Country:</strong> {dns.countryName}</p>
                                    )}
                                    {dns.countryIcon && (
                                        <img src={dns.countryIcon} alt="Country flag" className="w-8 h-auto" />
                                    )}
                                    {dns.sites && (
                                        <p><strong>Sites:</strong> {dns.sites}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {item.ip_change_history?.length > 0 && (
                        <div className="mt-2">
                            <h3 className="text-md font-medium">IP Change History</h3>
                            {item.ip_change_history.map((history, historyIndex) => (
                                <div key={historyIndex} className="mb-2">
                                    {history.ip_address && (
                                        <p><strong>IP Address:</strong> {history.ip_address}</p>
                                    )}
                                    {history.host && (
                                        <p><strong>Host:</strong> {history.host}</p>
                                    )}
                                    {history.date_when_website_was_using_ip && (
                                        <p><strong>Date When Website Was Using IP:</strong> {history.date_when_website_was_using_ip}</p>
                                    )}
                                    {history.date_when_found_that_website_changed_ip && (
                                        <p><strong>Date When Found That Website Changed IP:</strong> {history.date_when_found_that_website_changed_ip}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}
export default WhoisData