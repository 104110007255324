import React, { useState } from 'react';

const Webvuln_finderJobDetails = ({ result }) => {
    const [expandedIndex, setExpandedIndex] = useState(null); 

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index); 
    };

    const truncateText = (text = '') => {
        return text.length > 100 ? text.slice(0, 100) + '...' : text;
    };

    return (
        <div>
            {result.map((item, index) => {
                const isExpanded = expandedIndex === index;

                const description = item.description || '';
                const request = item.request || '';
                const response = item.response || '';

                return (
                    <div key={index} className="mb-4 p-4">
                        <h2 className="text-lg font-bold mb-2">{item.name}</h2>
                        <p className="text-sm text-gray-600 mb-2">Severity: {item.severity}</p>
                        
                        <p className="mb-2">
                            {isExpanded ? description : truncateText(description)}
                        </p>

                        <div className="mb-2">
                            <strong>Request:</strong>
                            <pre className="bg-gray-100 p-2 mt-1 rounded">
                                {isExpanded ? request : truncateText(request)}
                            </pre>
                        </div>

                        <div>
                            <strong>Response:</strong>
                            <pre className="bg-gray-100 p-2 mt-1 rounded">
                                {isExpanded ? response : truncateText(response)}
                            </pre>
                        </div>

                        <button
                            className="text-blue-500 mt-2"
                            onClick={() => toggleExpand(index)}
                        >
                            {isExpanded ? 'Show Less' : 'Show More'}
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default Webvuln_finderJobDetails;
