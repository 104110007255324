import React from 'react'
import { toast } from 'react-hot-toast';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import secureApiFetch from '../../services/secureFetchApi';

const CroneList = () => {

    const handleGetCall = (value) => {
        secureApiFetch(`/api/v1/${value}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                toast.success(data.message)
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                if (error.response && error.response.status === 500) {
                    toast.error('Internal Server Error (500)');
                } else {
                    toast.error('Error occurred during API call');
                }
            });
    }

    return (
        <Stack spacing={2} direction="row">
            <Button
                variant="contained"
                color='customColor'
                onClick={() => handleGetCall('multilevel_scan')}
            >
                Multi level Scan
            </Button>

            <Button
                variant="contained"
                color='customColor'
                onClick={() => handleGetCall('start_scan')}
            >
                Run Pending Scan
            </Button>

            <Button
                variant="contained"
                color='customColor'
                onClick={() => handleGetCall('scan_result')}
            >
                Fetch Scan Result
            </Button>
            <Button
                variant="contained"
                color='customColor'
                onClick={() => handleGetCall('schedule_scan_cron')}
            >
                Schedule Scan Cron
            </Button>

        </Stack>
    )
}

export default CroneList;
